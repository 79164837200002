import React from "react";

import NavbarLanding from "./components/NavbarLanding";
import PricingLanding from "./components/PricingLanding";
import PricingCardsLanding from "./components/PricingCardsLanding";
import FooterLanding from "./components/FooterLanding";

import useScrollToTop from "./hooks/useScrollToTop";

function ContactPage() {
	useScrollToTop();

	return (
		<div className="flex flex-col flex-grow md:items-center overflow-x-auto">
			<NavbarLanding />
			<PricingLanding />
			<PricingCardsLanding />
			<FooterLanding />
		</div>
	);
}

export default ContactPage;
