import React from "react";

import NavbarLanding from "./components/NavbarLanding";
import PricingCardsLanding from "./components/PricingCardsLanding";
import FooterLanding from "./components/FooterLanding";

import useScrollToTop from "./hooks/useScrollToTop";

function PricingPage() {
	useScrollToTop();

	return (
		<div className="flex flex-col flex-grow md:items-center overflow-x-auto">
			<NavbarLanding />
			<div name="Pricing" className="flex w-[1200px] h-[200px] px-[40px] py-[100px] items-center justify-center">
				<div className="flex flex-col items-center px-[300px] gap-5">
					<h1 className="text-5xl text-center font-semibold">ReportGenius Pricing</h1>
					<span className="text-xl text-center">Choose a plan that's right for you</span>
				</div>
			</div>
			<PricingCardsLanding />
			<div className="flex flex-col w-[1200px] h-[750px] items-center justify-center px-10 pb-24 gap-12">
				<span className="text-4xl">Pricing FAQ</span>
				<div className="flex flex-col gap-5">
					<ul class="menu w-[720px]">
						<li>
							<details close>
								<summary className="hover:bg-white text-2xl">What pricing plans are available for your SaaS product?</summary>
								<ul>
									<li>
										<span className="hover:bg-white">
											We offer three main pricing plans to cater to a variety of needs: the Basic plan for individuals or small teams just getting started, the Pro plan for
											growing businesses seeking more advanced features, and the Enterprise plan for large organizations requiring custom solutions. Each plan is designed to
											offer the best value for its respective tier, with detailed feature lists and user limits available on our pricing page.
										</span>
									</li>
								</ul>
							</details>
						</li>
					</ul>
					<ul class="menu w-[720px]">
						<li>
							<details close>
								<summary className="hover:bg-white text-2xl">How are the pricing plans billed?</summary>
								<ul>
									<li>
										<span className="hover:bg-white">
											Our pricing plans are billed monthly for now. It is easier for us to handle but we plan to introduce a year plan for commited users.
										</span>
									</li>
								</ul>
							</details>
						</li>
					</ul>
					<ul class="menu w-[720px]">
						<li>
							<details close>
								<summary className="hover:bg-white text-2xl">Can I change my pricing plan after signing up?</summary>
								<ul>
									<li>
										<span className="hover:bg-white">
											Absolutely! You can upgrade or downgrade your plan at any time directly from your account settings. If you upgrade, you'll be charged the prorated
											difference for the remainder of your billing cycle. If you downgrade, you'll receive a prorated credit towards your next bill.
										</span>
									</li>
								</ul>
							</details>
						</li>
					</ul>
					<ul class="menu w-[720px]">
						<li>
							<details close>
								<summary className="hover:bg-white text-2xl">What payment methods do you accept?</summary>
								<ul>
									<li>
										<span className="hover:bg-white">
											We use a third party system to handle the payments. Please refer to their informations to know which payment are accepted.
										</span>
									</li>
								</ul>
							</details>
						</li>
					</ul>
					<ul class="menu w-[720px]">
						<li>
							<details close>
								<summary className="hover:bg-white text-2xl">Do you offer any discounts or special pricing for nonprofits or educational institutions?</summary>
								<ul>
									<li>
										<span className="hover:bg-white">
											Yes, we're committed to supporting nonprofits and educational institutions by offering special discounted pricing. Eligible organizations can apply for our
											nonprofit or educational discount by submitting proof of status. Once approved, you'll receive a significant discount on any of our paid plans.
										</span>
									</li>
								</ul>
							</details>
						</li>
					</ul>
				</div>
			</div>
			<FooterLanding />
		</div>
	);
}

export default PricingPage;