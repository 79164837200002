import React from "react";

import img_check from "./img/i-checkmark-96.png";
import img_cancel from "./img/i-cancel-96.png";
import img_check_white from "./img/i-checkmark-96-white.png";
import img_cancel_white from "./img/i-cancel-96-white.png";

function PricingCardsLanding() {
	return (
		<div name="Pricing card" className="flex w-[1200px] h-[640px] items-start justify-center gap-8">
			<div className="flex flex-row items-center justify-center w-[340px] p-7">
				<div name="Price card 1" className="flex flex-col gap-y-10">
					<div className="flex flex-col gap-y-5">
						<div className="flex flex-col gap-y-3">
							<h1 className="text-3xl">Free</h1>
							<span>Ideal for individuals who need quick access to basic features</span>
						</div>
						<div className="flex flex-col gap-y-6">
							<div className="flex flex-row items-center gap-3">
								<span className="text-5xl">$0</span>
								<span>/month</span>
							</div>
							<button className="btn rounded-full text-base bg-white border-cyan-700 text-cyan-700 shadow-lg hover:bg-primary hover:text-white hover:border-white hover:scale-110">
								Get it for free
							</button>
						</div>
					</div>
					<div className="flex flex-col gap-y-3">
						<div className="flex flex-row items-center gap-2">
							<img src={img_check} alt="checkmark" style={{ width: "auto", height: "32px" }} />
							<span>1 Report creation without data</span>
						</div>
						<div className="flex flex-row items-center gap-2">
							<img src={img_check} alt="checkmark" style={{ width: "auto", height: "32px" }} />
							<span>1 quality report evaluation</span>
						</div>
						<div className="flex flex-row items-center gap-2">
							<img src={img_cancel} alt="checkmark" style={{ width: "auto", height: "32px" }} />
							<span>Unlimited access to features</span>
						</div>
					</div>
				</div>
			</div>
			<div className="flex flex-row items-start justify-center w-[340px] h-[630px] p-7 rounded-lg bg-primary">
				<div name="Price card 1" className="flex flex-col gap-y-10">
					<div className="flex flex-col gap-y-5">
						<div className="flex flex-col gap-y-3">
							<h1 className="text-3xl text-white">Professional</h1>
							<span className="text-white">Ideal for individuals who who need advanced features and tools</span>
						</div>
						<div className="flex flex-col gap-y-6">
							<div className="flex flex-row items-center gap-3">
								<span className="text-5xl text-white">$--,-</span>
								<span className="text-white">/month</span>
							</div>
							<button className="btn rounded-full text-base bg-white text-cyan-700 hover:bg-primary hover:text-white hover:border-white hover:scale-110 ">Start as a Pro</button>
						</div>
					</div>
					<div className="flex flex-col gap-y-3">
						<div className="flex flex-row items-center gap-2">
							<img src={img_check_white} alt="checkmark" style={{ width: "auto", height: "32px" }} />
							<span className="text-white">Unlimited report creation with data</span>
						</div>
						<div className="flex flex-row items-center gap-2">
							<img src={img_check_white} alt="checkmark" style={{ width: "auto", height: "32px" }} />
							<span className="text-white">Unlimited quality report evaluation</span>
						</div>
						<div className="flex flex-row items-center gap-2">
							<img src={img_check_white} alt="cancel" style={{ width: "auto", height: "32px" }} />
							<span className="text-white">Access to up coming features</span>
						</div>
						{/*<div className="flex flex-row items-center gap-2">
							<img src={img_cancel_white} alt="cancel" style={{ width: "auto", height: "32px" }} />
							<span className="text-white">Min 10 transactions per week</span>
						</div>*/}
					</div>
				</div>
			</div>
			<div className="flex flex-row items-center justify-center w-[340px] p-7">
				<div name="Price card 1" className="flex flex-col gap-y-10">
					<div className="flex flex-col gap-y-5">
						<div className="flex flex-col gap-y-3">
							<h1 className="text-3xl">Entreprise</h1>
							<span>Ideal for businesses who need personalized services and security</span>
						</div>
						<div className="flex flex-col gap-y-6">
							<div className="flex flex-row items-center gap-3">
								<span className="text-5xl">$--,-</span>
								<span>/month</span>
							</div>
							<button className="btn rounded-full text-base bg-white border-cyan-700 text-cyan-700 shadow-lg hover:bg-primary hover:text-white hover:border-white hover:scale-110">
								Contact us
							</button>
						</div>
					</div>
					<div className="flex flex-col gap-y-3">
						<div className="flex flex-row items-center gap-2">
							<img src={img_check} alt="checkmark" style={{ width: "auto", height: "32px" }} />
							<span>Unlimited report creation with data</span>
						</div>
						<div className="flex flex-row items-center gap-2">
							<img src={img_check} alt="checkmark" style={{ width: "auto", height: "32px" }} />
							<span>Unlimited quality report evaluation</span>
						</div>
						<div className="flex flex-row items-center gap-2">
							<img src={img_check} alt="cancel" style={{ width: "auto", height: "32px" }} />
							<span>Access to up coming features</span>
						</div>
						<div className="flex flex-row items-center gap-2">
							<img src={img_check} alt="cancel" style={{ width: "auto", height: "32px" }} />
							<span>Multi account</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default PricingCardsLanding;
