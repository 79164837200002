import React from "react";

import NavbarLanding from "./components/NavbarLanding";
import FooterLanding from "./components/FooterLanding";

import useScrollToTop from "./hooks/useScrollToTop";

function TermsOfServicePage() {
	useScrollToTop();

	return (
		<div className="flex flex-col flex-grow md:items-center overflow-x-auto">
			<NavbarLanding />
			<div className="flex flex-col w-[1100px] pb-3 gap-4 font-normal">
				<span className="font-bold text-3xl">Terms of Service</span>
				<span>Effective Date: ""</span>
				<span>
					By accessing and using the services offered on this website, you agree to comply with and be bound by these Terms of Service. If you do not agree with any part of
					these terms, please refrain from using our services.
				</span>
				<span className="font-bold text-xl">Use of Services</span>
				<span>
					This website provides report generation and quality evaluation. The generated content and results are provided "as is" and we do not guarantee their accuracy or reliability.
				</span>
				<span className="font-bold text-xl">Intellectual Property</span>
				<span>
					The content and materials available on this website, including but not limited to text, graphics, logos, button icons, images, audio clips, and software, are the property of
					this service and protected by applicable copyright and intellectual property laws.
				</span>
				<span>
					By using our services, you are granted a limited, revocable, non-exclusive, and non-transferable license to access and make personal use of the content. However, you must not
					reproduce, modify, distribute, display, perform or create derivative works based upon any part of the website or its content without our prior written consent.
				</span>
				<span className="font-bold text-xl">Limitation of Liability</span>
				<span>
					To the fullest extent permitted by law, we shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages arising out of or in connection
					with the use or performance of our services. This includes, but is not limited to, damages for loss of profits, business interruption, or loss of data.
				</span>
				<span className="font-bold text-xl">Indemnification</span>
				<span>
					You agree to defend, indemnify and hold this service harmless from and against any claims, liabilities, damages, losses, costs, or expenses arising out of or related to your use of our
					services or any violation of these Terms of Service.
				</span>
				<span className="font-bold text-xl">Termination</span>
				<span>
					We reserve the right to terminate or suspend access to our services without prior notice or liability for any reason, including without limitation if you breach these Terms of
					Service. All provisions of the Terms of Service which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions,
					warranty disclaimers, indemnity and limitations of liability.
				</span>
				<span className="font-bold text-xl">Refund Policy</span>
				<span className="font-bold">1. Refund Eligibility</span>
				<span>We offer refunds for subscription fees under the following conditions:</span>
				<span>Technical Issues: If you encounter technical issues with our service that we are unable to resolve, we will provide a refund for the current subscription period.</span>
				<span>Please note that under normal circumstances, subscription fees are non-refundable.</span>
				<span className="font-bold">2. Cancellation of Subscription</span>
				<span>Users may cancel their subscription at any time, but such cancellations will not result in refunds for previous subscription periods.</span>
				<span>Note: If you cancel your subscription, pro features will still be available until the end of the billing cycle.</span>
				<span className="font-bold text-xl">Governing Law</span>
				<span>
					These Terms of Service shall be governed by and construed in accordance with the laws of "Country". Any dispute arising out of or relating to these terms and conditions
					shall be subject to the exclusive jurisdiction of the courts of "Country".
				</span>
				<span className="font-bold text-xl">Contact Us</span>
				<span>If you have any questions or concerns about these Terms of Service, please contact us at:</span>
				<span>Email: ""</span>
				<span>We reserve the right to update or change these Terms of Service at any time. Any changes will be posted on this page and become effective immediately upon posting.</span>
			</div>
			<FooterLanding />
		</div>
	);
}

export default TermsOfServicePage;
