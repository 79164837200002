import React from "react";

import NavbarLanding from "./components/NavbarLanding";
import FooterLanding from "./components/FooterLanding";

import useScrollToTop from "./hooks/useScrollToTop";

function PrivacyPolicyPage() {
	useScrollToTop();

	return (
		<div className="flex flex-col flex-grow md:items-center overflow-x-auto">
			<NavbarLanding />
			<div className="flex flex-col w-[1100px] pb-3 gap-4 font-normal">
				<span className="font-bold text-3xl">Privacy Policy</span>
				<span>Effective Date: ""</span>
				<span>
					This Privacy Policy explains how this website collects, uses and protects your personal information when using our services. We are committed to ensuring that your
					privacy is protected.
				</span>
				<span className="font-bold text-xl">Information We Collect</span>
				<span>
					When you visit our website, we may collect limited information that does not personally identify you. This may include your IP address, browser type, referring/exit pages, and
					usage information to analyze trends and improve our website.
				</span>
				<span className="font-bold text-xl">How We Use Your Information</span>
				<span>The information we collect is used for the following purposes:</span>
				<span>To analyze and improve the content, functionality, and performance of our website.</span>
				<span>To provide support and assistance.</span>
				<span className="font-bold text-xl">Data Sharing and Security</span>
				<span>
					We understand the importance of keeping your personal data secure. We do not sell, trade, or rent your personal information to third parties. However, we may share your information
					with trusted third-party service providers who assist us in operating our website and providing our services.
				</span>
				<span>
					We use third parties for our payment processor to handle financial transactions securely. When processing payments, some of your data will be passed to these serives, as necessary, to
					complete the transaction. We do not store or have access to your payment card details.
				</span>
				<span className="font-bold text-xl">Your Choices</span>
				<span>We respect your privacy rights and provide you with the option to:</span>
				<span>Opt out of the use of cookies by adjusting your browser settings.</span>
				<span className="font-bold text-xl">Contact Us</span>
				<span>If you have any questions or concerns about this Privacy Policy or our practices, please contact us at:</span>
				<span>Email: ""</span>
				<span>We reserve the right to update or change this Privacy Policy at any time. Any changes will be posted on this page and become effective immediately upon posting.</span>
			</div>
			<FooterLanding />
		</div>
	);
}

export default PrivacyPolicyPage;
