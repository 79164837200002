import React from "react";
import { Link } from "react-router-dom";

import img_2_1 from "./img/report-quality.png";

function FeatureTwoLanding() {
	return (
		<div name="Feature 2" className="flex flex-row w-[1200px] h-[400px] px-[40px] py-[80px] justify-center">
			<div name="text-and-buttons" className="flex flex-col items-start justify-center w-[600px] gap-y-10">
				<div name="text" className="flex flex-col items-start justify-center gap-y-5">
					<div name="title" className="flex">
						<h2 className="text-4xl">Benchmark your reports to ensure top industry standards</h2>
					</div>
					<div name="sub-title" className="flex">
						<p>Upload your report, let the AI suggests improvements to reach high quality content that will make the difference.</p>
					</div>
				</div>
				<div className="flex flex-row">
					<Link to="/connection" className="btn rounded-full text-xl bg-white border-cyan-700 text-cyan-700 shadow-lg hover:bg-primary hover:text-white hover:border-white hover:scale-110">
						Use the app
					</Link>
				</div>
			</div>
			<div name="image" className="flex flex-col relative w-[500px]">
				<div className="flex absolute top-[-30px] left-[50px]">
					<img src={img_2_1} alt="Dashboard-mini" className="drop-shadow-xl" />
				</div>
			</div>
		</div>
	);
}

export default FeatureTwoLanding;