import React from "react";

import FormAndDisplay from "./components/FormAndDisplay";
import NavbarLanding from "../landing/components/NavbarLanding";

function App() {
	return (
		<div className="App">
			<NavbarLanding />
			<main className="container mx-auto p-4">
				<FormAndDisplay />
			</main>
		</div>
	);
}

export default App;
