import React from "react";

import NavbarLanding from "./components/NavbarLanding";
import HeroLanding from "./components/HeroLanding";
import ValidationLanding from "./components/ValidationLanding";
import FeatureOneLanding from "./components/FeatureOneLanding";
import FeatureTwoLanding from "./components/FeatureTwoLanding";
import FeatureThreeLanding from "./components/FeatureThreeLanding";
import FeatureOverviewLanding from "./components/FeatureOverviewLanding";
import PricingLanding from "./components/PricingLanding";
import PricingCardsLanding from "./components/PricingCardsLanding";
import TestimonyLanding from "./components/TestimonyLanding";
import CTALanding from "./components/CTALanding";
import FAQLanding from "./components/FAQLanding";
import FooterLanding from "./components/FooterLanding";

import useScrollToTop from "./hooks/useScrollToTop";

function LandingPage() {
	useScrollToTop();

	return (
		<div className="flex flex-col flex-grow md:items-center overflow-x-auto">
			<NavbarLanding />
			<HeroLanding />
			<ValidationLanding />
			<FeatureOneLanding />
			<FeatureTwoLanding />
			<FeatureThreeLanding />
			<FeatureOverviewLanding />
			<PricingLanding />
			<PricingCardsLanding />
			<TestimonyLanding />
			<CTALanding />
			<FAQLanding />
			<FooterLanding />
		</div>
	);
}

export default LandingPage;
