import React from "react";
import { FileUploader } from "react-drag-drop-files";

const FileUploadForm = ({ files, userInput, handleFileChange, handleChange, error, response }) => {
	const fileTypes = ["XLSX", "XLS", "CSV"];

	const FileList = ({ files }) => {
		return (
			<div>
				{files && files.length > 0 ? (
					<table>
						<thead>
							<tr>
								<th>File Name</th>
							</tr>
						</thead>
						<tbody>
							{files.map((file, index) => (
								<tr key={index}>
									<td>{file.name}</td>
								</tr>
							))}
						</tbody>
					</table>
				) : (
					<p>No files uploaded yet</p>
				)}
			</div>
		);
	};
	return (
		<div className="flex w-full max-w-[1200px]">
			<div className="flex flex-col w-full">
				<span>For now only .csv, .xls and .xlsx</span>
				<FileUploader multiple={true} handleChange={handleFileChange} name="file" types={fileTypes} />
				<FileList files={files} />
				<div className="py-4">
					<div className="mb-4">
						<label className="block text-gray-700">OpenAI API Key</label>
						<input type="text" name="openAIKey" value={userInput.openAIKey} onChange={handleChange} className="input input-bordered w-full" />
					</div>
					<div className="mb-4">
						<label className="block text-gray-700">Background</label>
						<input type="text" name="background" value={userInput.background} onChange={handleChange} className="input input-bordered w-full" />
					</div>
					<div className="mb-4">
						<label className="block text-gray-700">Key Points</label>
						<input type="text" name="keyPoints" value={userInput.keyPoints} onChange={handleChange} className="input input-bordered w-full" />
					</div>
					<div className="mb-4">
						<label className="block text-gray-700">Conclusion</label>
						<input type="text" name="conclusion" value={userInput.conclusion} onChange={handleChange} className="input input-bordered w-full" />
					</div>
				</div>
				{response && <p className="text-green-500 mt-2">{response}</p>}
				{error && <p className="text-red-500 mt-2">{error}<br></br>Please reload the page and restart the process.</p>}
			</div>
		</div>
	);
};

export default FileUploadForm;
