import React from "react";
import {Link} from 'react-router-dom';

function NavbarLanding() {
	return (
		<div name="nav" className="navbar w-[1200px] h-[85px]">
			<Link to="/" className="text-xl m-2 hover:scale-110">
				ReportGenius
			</Link>
			<div className="flex flex-grow justify-center">
				<Link to="/pricing" className="btn btn-ghost text-base hover:scale-110 hover:bg-transparent w-[100px] m-2">
					Pricing
				</Link>
				<Link to="/features" className="btn btn-ghost text-base hover:scale-110 hover:bg-transparent w-[100px] m-2">
					Features
				</Link>
				<Link to="/blog" className="btn btn-ghost text-base hover:scale-110 hover:bg-transparent w-[100px] m-2">
					Blog
				</Link>
				<Link to="/about" className="btn btn-ghost text-base hover:scale-110 hover:bg-transparent w-[100px] m-2">
					About
				</Link>
			</div>
			<Link to="/connection" className="btn rounded-full text-xl bg-primary text-white hover:bg-white hover:text-cyan-700 hover:border-cyan-700 hover:scale-110 w-[120px] m-2">
				Sign up
			</Link>
			<Link to="/connection" className="btn btn-ghost text-xl hover:scale-110 hover:bg-transparent w-[120px] m-2">
				Log In
			</Link>
		</div>
	);
}

export default NavbarLanding;