import React from "react";

import img_p_6 from "./img/p-6.jpg";

function TestimonyLanding() {
	return (
		<div name="Testimonials" className="flex flex-col w-[1200px] h-[930px] items-center justify-center gap-12">
			<div className="flex flex-col items-center justify-center gap-5">
				<span className="text-4xl">Customer testimonials</span>
				<span>Don't just take our word for it. See what our users are saying.</span>
			</div>
			<div className="grid grid-rows-2 grid-flow-col gap-10">
				<div className="flex flex-col w-[320px] h-[275px] items-start justify-center p-7 gap-5 rounded-lg border-2 border-cyan-700">
					<span className="text-lg">
						Testimonial content here
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
					</span>
					<div className="flex flex-row items-center justify-start gap-2.5">
						<img src={img_p_6} alt="profile 1" style={{ width: "48px", height: "48px" }} className="object-cover rounded-full" />
						<div className="flex flex-col items-start justify-center gap-1">
							<span className="font-semibold">Name</span>
							<span>Founder @ Company</span>
						</div>
					</div>
				</div>
				<div className="flex flex-col w-[320px] h-[275px] items-start justify-center p-7 gap-5 rounded-lg border-2 border-cyan-700">
					<span className="text-lg">
						Testimonial content here
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
					</span>
					<div className="flex flex-row items-center justify-start gap-2.5">
						<img src={img_p_6} alt="profile 1" style={{ width: "48px", height: "48px" }} className="object-cover rounded-full" />
						<div className="flex flex-col items-start justify-center gap-1">
							<span className="font-semibold">Name</span>
							<span>Founder @ Company</span>
						</div>
					</div>
				</div>
				<div className="flex flex-col w-[320px] h-[275px] items-start justify-center p-7 gap-5 rounded-lg border-2 border-cyan-700">
					<span className="text-lg">
						Testimonial content here
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
					</span>
					<div className="flex flex-row items-center justify-start gap-2.5">
						<img src={img_p_6} alt="profile 1" style={{ width: "48px", height: "48px" }} className="object-cover rounded-full" />
						<div className="flex flex-col items-start justify-center gap-1">
							<span className="font-semibold">Name</span>
							<span>Founder @ Company</span>
						</div>
					</div>
				</div>
				<div className="flex flex-col w-[320px] h-[275px] items-start justify-center p-7 gap-5 rounded-lg border-2 border-cyan-700">
					<span className="text-lg">
						Testimonial content here
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
					</span>
					<div className="flex flex-row items-center justify-start gap-2.5">
						<img src={img_p_6} alt="profile 1" style={{ width: "48px", height: "48px" }} className="object-cover rounded-full" />
						<div className="flex flex-col items-start justify-center gap-1">
							<span className="font-semibold">Name</span>
							<span>Founder @ Company</span>
						</div>
					</div>
				</div>
				<div className="flex flex-col w-[320px] h-[275px] items-start justify-center p-7 gap-5 rounded-lg border-2 border-cyan-700">
					<span className="text-lg">
						Testimonial content here
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
					</span>
					<div className="flex flex-row items-center justify-start gap-2.5">
						<img src={img_p_6} alt="profile 1" style={{ width: "48px", height: "48px" }} className="object-cover rounded-full" />
						<div className="flex flex-col items-start justify-center gap-1">
							<span className="font-semibold">Name</span>
							<span>Founder @ Company</span>
						</div>
					</div>
				</div>
				<div className="flex flex-col w-[320px] h-[275px] items-start justify-center p-7 gap-5 rounded-lg border-2 border-cyan-700">
					<span className="text-lg">
						Testimonial content here
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
					</span>
					<div className="flex flex-row items-center justify-start gap-2.5">
						<img src={img_p_6} alt="profile 1" style={{ width: "48px", height: "48px" }} className="object-cover rounded-full" />
						<div className="flex flex-col items-start justify-center gap-1">
							<span className="font-semibold">Name</span>
							<span>Founder @ Company</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default TestimonyLanding;
