import React, { useEffect, useState } from "react";

const BackendStatusChecker = () => {
	const [isBackendOn, setIsBackendOn] = useState(false);

	useEffect(() => {
		const checkBackendStatus = async () => {
			try {
				const response = await fetch("https://reportgenius-main-backend.onrender.com");
				if (response.ok) {
					setIsBackendOn(true);
				} else {
					setIsBackendOn(false);
				}
			} catch (error) {
				setIsBackendOn(false);
			}
		};

		checkBackendStatus();
	}, []);

	return <div>{isBackendOn ? <div>The service is AVAILABLE</div> : <div>The service is NOT AVAILABLE, please wait ...</div>}</div>;
};

export default BackendStatusChecker;
