import React from "react";

import NavbarLanding from "./components/NavbarLanding";
import FeatureOverviewLanding from "./components/FeatureOverviewLanding";
import FeatureOneLanding from "./components/FeatureOneLanding";
import FeatureTwoLanding from "./components/FeatureTwoLanding";
import FeatureThreeLanding from "./components/FeatureThreeLanding";
import FooterLanding from "./components/FooterLanding";

import useScrollToTop from "./hooks/useScrollToTop";

function FeaturesPage() {
	useScrollToTop();

	return (
		<div className="flex flex-col flex-grow md:items-center overflow-x-auto">
			<NavbarLanding />
			<FeatureOverviewLanding />
			<FeatureOneLanding />
			<FeatureTwoLanding />
			<FeatureThreeLanding />
			<FooterLanding />
		</div>
	);
}

export default FeaturesPage;
