import React, { useState, useRef } from "react";
import FileUploadForm from "./FileUploadForm";
import ChartDisplay from "./ChartDisplay";
import BackendStatusChecker from "./BackendStatusChecker";
import { v4 as uuidv4 } from "uuid";

function FormAndDisplay(){
	const user_id = useRef(uuidv4()); // Generate user_id once

	const [files, setFiles] = useState([]);
	const [userInput, setUserInput] = useState({
		openAIKey: "",
		background: "",
		keyPoints: "",
		conclusion: "",
	});

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [response, setResponse] = useState(null);
	const [chartData, setChartData] = useState(null);

	const handleFileChange = (fileList) => {
		setFiles(Array.from(fileList));
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setUserInput({ ...userInput, [name]: value });
	};

	const handleUpload = async () => {
		if (files.length === 0 || !userInput.openAIKey || !userInput.background || !userInput.keyPoints || !userInput.conclusion) {
			setError("Please fill all fields and upload at least one file.");
			return;
		}

		setLoading(true);
		setError(null);
		setResponse(null);

		const formFile = new FormData();
		files.forEach((file) => {
			formFile.append("files", file);
		});
		formFile.append("user_id", user_id.current);

		const formUser = new FormData();
		Object.entries(userInput).forEach(([name, value]) => {
			formUser.append(name, value);
		});
		formUser.append("user_id", user_id.current);

		try {
			const uploadResponse = await fetch("https://reportgenius-main-backend.onrender.com/upload", {
				method: "POST",
				body: formFile,
				credentials: "include",
			});
			const uploadData = await uploadResponse.json();

			const userResponse = await fetch("https://reportgenius-main-backend.onrender.com/user-input", {
				method: "POST",
				body: formUser,
				credentials: "include",
			});
			const uploadUser = await userResponse.json();

			setResponse(uploadData.message || uploadUser.message);

			await handleProcessData();
		} catch (error) {
			console.log(error);
			setError("There was an error uploading the files or submitting the form!");
		} finally {
			setLoading(false);
		}
	};

	const handleProcessData = async () => {
		const url = new URL("https://reportgenius-main-backend.onrender.com/process");
		url.searchParams.append("user_id", user_id.current);

		const processResponse = await fetch(url, {
			method: "GET",
			credentials: "include",
		});
		const processData = await processResponse.json();
		console.log(processData);
		
		if (processResponse.ok) {
			setChartData(processData);
		} else {
			throw new Error(processData.message || "Failed to process data.");
		}
	};

	return (
		<div className="flex flex-col justify-center items-center w-full">
			<span>For cost we use a server that can shut down the service is unused after a while.</span>
			<span>Please check below if the service is running before sending your request. The startup can take several minutes.</span>
			<BackendStatusChecker />
			<br></br>
			<span>We know that asking an OpenAI key can be sensible, we don't save any data and we rommend you to revoke the key that you with us after leaving the service.</span>
			<FileUploadForm files={files} userInput={userInput} handleFileChange={handleFileChange} handleChange={handleChange} error={error} response={response} />
			<div className="flex justify-start">
				<button onClick={handleUpload} className="btn btn-primary mt-2 text-lg w-[300px]">
					Process Data
				</button>
			</div>
			<div className="flex justify-center text-2xl font-semibold">{loading ? "Processing..." : ""}</div>
			<ChartDisplay chartData={chartData} />
		</div>
	);
};

export default FormAndDisplay;
