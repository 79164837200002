import React from "react";

import NavbarLanding from "./components/NavbarLanding";
import FooterLanding from "./components/FooterLanding";

import useScrollToTop from "./hooks/useScrollToTop";

function AboutPage() {
	useScrollToTop();

	return (
		<div className="flex flex-col flex-grow md:items-center overflow-x-auto">
			<NavbarLanding />
			<div className="flex flex-col w-[1100px] pb-3 gap-4 font-normal">
				<span className="text-xl font-bold">About [Name of Your SaaS]</span>
				<span className="text-lg">
					At [Name of Your SaaS], we believe in maximizing productivity and focusing on what truly matters. Born from a personal struggle with time-consuming reporting tasks, our platform is
					designed to transform the way individuals and companies approach report writing.
				</span>
				<span className="text-xl font-bold">Who We Are</span>
				<span className="text-lg">
					We are innovators, problem-solvers, and advocates for efficiency. Our mission is to eliminate tedious, repetitive tasks, freeing up time for activities that make a real difference.
					We understand that in the fast-paced world of business, efficiency is key. That's why we've created a tool that not only supports your reporting needs but also enhances the quality
					and impact of your work.
				</span>
				<span className="text-xl font-bold">What We Do</span>
				<span className="text-lg">
					[Name of Your SaaS] offers a cutting-edge solution to automate the creation of high-quality reports within minutes, not days. Our core features include:
				</span>
				<ul className="list-disc pl-5 text-lg">
					<li>Automated Report Creation: Generate comprehensive reports quickly and accurately.</li>
					<li>Report Quality Evaluator: Ensure your reports meet high standards with our built-in quality checks.</li>
					<li>Future Integrations: We are working towards enabling automatic data reporting by connecting directly to various data sources.</li>
				</ul>
				<span className="text-lg">Our software is tailored for anyone bogged down by the daunting task of report writing, from freelancers to large corporations.</span>
				<span className="text-xl font-bold">Why Choose Us?</span>
				<span className="text-lg">
					Our tool is not just about saving time. It's about enhancing the quality of your reports and the insights derived from them. With [Name of Your SaaS], you'll spend less time on
					routine tasks and more on strategic thinking and creativity.
				</span>
				<span className="text-lg">Ready to transform your reporting process? Join us on this journey towards greater efficiency and better reporting.</span>
			</div>
			<FooterLanding />
		</div>
	);
}

export default AboutPage;