import React from "react";
import { Link } from "react-router-dom";

import img_3_1 from "./img/statistics.png";
import img_3_2 from "./img/neural.png";

function FeatureThreeLanding() {
	return (
		<div name="Feature 1" className="flex flex-row w-[1200px] h-[400px] px-[40px] py-[80px] justify-center">
			<div name="image" className="flex flex-col relative w-[600px]">
				<div className="flex flex-col absolute top-[40px] left-[120px]">
					<img src={img_3_1} alt="Dashboard-mini" className="drop-shadow-xl" />
				</div>
				<div className="flex flex-col absolute top-[-70px] left-[320px]">
					<img src={img_3_2} alt="Contact" style={{ width: "auto", height: "200px" }} className="drop-shadow-xl" />
				</div>
			</div>
			<div name="text-and-buttons" className="flex flex-col w-[600px] items-start justify-center gap-y-10">
				<div name="text" className="flex flex-col items-start justify-center gap-y-5">
					<div name="title" className="flex">
						<h2 className="text-4xl">Features coming soon!</h2>
					</div>
					<div name="sub-title" className="flex">
						<ul className="list-disc pl-5">
							<li>Automatic data analysis and graph creation.</li>
							<li>Automatic data reporting.</li>
							<li>Discuss with your document database and summarize it.</li>
							<li>Personalizable template library.</li>
						</ul>
					</div>
				</div>
				<div className="flex flex-row">
					<Link to="/connection" className="btn rounded-full text-xl bg-white border-cyan-700 text-cyan-700 shadow-lg hover:bg-primary hover:text-white hover:border-white hover:scale-110">
						Use the app
					</Link>
				</div>
			</div>
		</div>
	);
}

export default FeatureThreeLanding;
