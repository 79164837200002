import React from "react";
import { Link } from "react-router-dom";

import NavbarLanding from "./components/NavbarLanding";
import FooterLanding from "./components/FooterLanding";

import useScrollToTop from "./hooks/useScrollToTop";

import img_p_6 from "./components/img/p-6.jpg";

function BlogPage() {
	useScrollToTop();

	return (
		<div className="flex flex-col flex-grow md:items-center overflow-x-auto">
			<NavbarLanding />
			<div name="Testimonials" className="flex flex-col w-[1200px] h-[930px] items-center justify-start gap-10 pt-12">
				<div className="flex flex-col items-center justify-center">
					<span className="text-5xl">Company Blog</span>
				</div>
				<div className="flex flex-row w-[1070px] h-[400px] items-start justify-center p-4 gap-10">
					<div className="flex flex-row items-center justify-start gap-2.5">
						<img src={img_p_6} alt="profile 1" style={{ width: "550px", height: "350px" }} className="object-cover" />
					</div>
					<div className="flex flex-col w-[550px] gap-8">
						<span className="text-3xl">Title of the article</span>
						<span>
							Preview article.
							<br></br>
							<br></br>
							<br></br>
							<br></br>
							<br></br>
						</span>
						<span className="text-xl">Author, Date</span>
						<Link
							to="/connection"
							className="btn rounded-full text-xl bg-white border-cyan-700 text-cyan-700 shadow-lg hover:bg-primary hover:text-white hover:border-white hover:scale-110"
						>
							Read the article
						</Link>
					</div>
				</div>
				<div className="grid grid-rows-1 grid-cols-3 grid-flow-col gap-10">
					<div className="flex flex-col w-[320px] h-[275px] items-start justify-center p-7 gap-5 rounded-lg border-2 border-cyan-700">
						<span className="text-lg">
							Blog content.
							<br></br>
							<br></br>
							<br></br>
							<br></br>
							<br></br>
						</span>
						<div className="flex flex-row items-center justify-start gap-2.5">
							<img src={img_p_6} alt="profile 1" style={{ width: "48px", height: "48px" }} className="object-cover rounded-full" />
							<div className="flex flex-col items-start justify-center gap-1">
								<span className="font-semibold">Name</span>
								<span>Founder @ Company</span>
							</div>
						</div>
					</div>
					<div className="flex flex-col w-[320px] h-[275px] items-start justify-center p-7 gap-5 rounded-lg border-2 border-cyan-700">
						<span className="text-lg">
							Blog content.
							<br></br>
							<br></br>
							<br></br>
							<br></br>
							<br></br>
						</span>
						<div className="flex flex-row items-center justify-start gap-2.5">
							<img src={img_p_6} alt="profile 1" style={{ width: "48px", height: "48px" }} className="object-cover rounded-full" />
							<div className="flex flex-col items-start justify-center gap-1">
								<span className="font-semibold">Name</span>
								<span>Founder @ Company</span>
							</div>
						</div>
					</div>
					<div className="flex flex-col w-[320px] h-[275px] items-start justify-center p-7 gap-5 rounded-lg border-2 border-cyan-700">
						<span className="text-lg">
							Blog content.
							<br></br>
							<br></br>
							<br></br>
							<br></br>
							<br></br>
						</span>
						<div className="flex flex-row items-center justify-start gap-2.5">
							<img src={img_p_6} alt="profile 1" style={{ width: "48px", height: "48px" }} className="object-cover rounded-full" />
							<div className="flex flex-col items-start justify-center gap-1">
								<span className="font-semibold">Name</span>
								<span>Founder @ Company</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<FooterLanding />
		</div>
	);
}

export default BlogPage;
