import React from "react";

function FAQLanding() {
	return (
		<div className="flex flex-col w-[1200px] h-[570px] items-center justify-center px-10 py-16 gap-12">
			<span className="text-4xl">FAQ</span>
			<div className="flex flex-col gap-5">
				<ul class="menu w-[720px]">
					<li>
						<details close>
							<summary className="hover:bg-white text-2xl">What does ReportGenius do ?</summary>
							<ul>
								<li>
									<span className="hover:bg-white">
										ReportGenius helps users convert data into qualitative report, review the quality of a report to meet top industry standard and more.
									</span>
								</li>
							</ul>
						</details>
					</li>
				</ul>
				<ul class="menu w-[720px]">
					<li>
						<details close>
							<summary className="hover:bg-white text-2xl">What does a report generator do ?</summary>
							<ul>
								<li>
									<span className="hover:bg-white">
										The goal of the report generator is to help users generate type of documents using natural language, with the use of AI. This
										saves users time instead of manually writting the  whole report, they can input their data and some extra information and get a very qualitative draft in few seconds.
									</span>
								</li>
							</ul>
						</details>
					</li>
				</ul>
				<ul class="menu w-[720px]">
					<li>
						<details close>
							<summary className="hover:bg-white text-2xl">What is the "free" tier ?</summary>
							<ul>
								<li>
									<span className="hover:bg-white">
										Yes we have. It gives a limited access for the user to try the product.
									</span>
								</li>
							</ul>
						</details>
					</li>
				</ul>
				<ul class="menu w-[720px]">
					<li>
						<details close>
							<summary className="hover:bg-white text-2xl">Do you have a refund policy ?</summary>
							<ul>
								<li>
									<span className="hover:bg-white">
										Our refund policy lasts 30 days from the date of your last payment. If 30 days have gone by since your most recent payment, unfortunately, we cannot offer you a
										refund or credit. Eligibility for a refund also depends on the use of the serive during this period, if it is judge too high you will not be eligible for a refund. This is because
										extensive usage significantly depletes the resources available to other users and the system itself. Contact [email] for more information.
									</span>
								</li>
							</ul>
						</details>
					</li>
				</ul>
			</div>
		</div>
	);
}

export default FAQLanding;
