import React from 'react';

import { CopyBlock } from 'react-code-blocks';

function ChartDisplay({ chartData }) {
	const { charts_pictures = [], charts_codes = [], charts_questions = [], charts_rationales = [] } = chartData || {};

	if (!chartData) {
		return (
			<div>
			</div>
		);
	}

	return (
		<div>
			<div className="flex flex-col justify-stretch max-w-[1200px] space-y-5">
				{charts_pictures.map((image, index) => (
					<div key={index} className="flex flex-col">
						<div className="py-4">
							<div className="w-[650px] font-semibold">
								<span>{charts_questions[index]}</span>
							</div>
							<div className="w-[650px]">
								<span>{charts_rationales[index]}</span>
							</div>
						</div>
						<div className="flex flex-row space-x-5 items-start">
							<div className="w-[500px] flex-shrink-0">
								<img src={`data:image/png;base64,${image}`} alt={`Chart ${index + 1}`} className="w-full h-auto" />
							</div>
							<div className="flex-1">
								<h3 className="text-lg font-semibold mb-2">Generated Code</h3>
								<div className="flex-1 w-[680px] h-[320px] overflow-auto">
									<CopyBlock language={"python"} text={charts_codes[index]} showLineNumbers={true} theme={"dracula"} wrapLines={true} $codeBlock={true} />
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}

export default ChartDisplay;
