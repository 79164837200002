import React from "react";

import img_ai from "./img/i-ai-100.png";
import img_doc from "./img/i-document-96.png";
import img_lb from "./img/i-lightning-bolt-96.png";

function FeatureOverviewLanding() {
	return (
		<div name="Feature overview" className="flex w-[1200px] h-[440px] px-[40px] items-center justify-center">
			<div className="flex flex-col items-center w-full">
				<div className="flex justify-center w-[600px]">
					<h1 className="text-5xl text-center">Make report creation effortless and spend more time on valuable tasks</h1>
				</div>
				<div className="flex flex-row justify-between px-[50px] py-[20px] gap-11">
					<div className="flex flex-col w-1/3 items-start gap-3">
						<img src={img_ai} alt="Ai-logo" style={{ width: "auto", height: "40px" }} className="drop-shadow-xl" />
						<h1 className="text-4xl">Effortless creation</h1>
						<span>The AI does all the work for you.</span>
					</div>
					<div className="flex flex-col w-1/3  items-start gap-3">
						<img src={img_doc} alt="doc-logo" style={{ width: "auto", height: "40px" }} className="drop-shadow-xl" />
						<h1 className="text-4xl">Quality content</h1>
						<span>Have the upper hand to reach top industry standards.</span>
					</div>
					<div className="flex flex-col w-1/3 items-start gap-3">
						<img src={img_lb} alt="lightning-bolt-logo" style={{ width: "auto", height: "40px" }} className="drop-shadow-xl" />
						<h1 className="text-4xl">Save time</h1>
						<span>Do reporting has never been faster.</span>
					</div>
				</div>
			</div>
		</div>
	);
}

export default FeatureOverviewLanding;
