import {React} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import './App.css';

import { SpeedInsights } from "@vercel/speed-insights/react";
import { Analytics } from "@vercel/analytics/react";

import LandingPage from './landing/LandingPage';
import PricingPage from './landing/PricingPage';
import FeaturesPage from './landing/FeaturesPage';
import BlogPage from './landing/BlogPage';
import AboutPage from './landing/AboutPage';
import ContactPage from './landing/ContactPage';
import TermsOfServicePage from './landing/TermsOfServicePage';
import PrivacyPolicyPage from './landing/PrivacyPolicyPage';

import AppPage from './apppage/AppPage';

function App() {

  return (
    <Router>
      <div className="flex place-content-center">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/features" element={<FeaturesPage />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/terms-of-service" element={<TermsOfServicePage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/connection" element={<AppPage />} />
        </Routes>
      </div>  
    </Router>    
  );
};

export default App;


