import React from "react";

function PricingLanding() {
	return (
		<div name="Pricing" className="flex w-[1200px] h-[290px] px-[40px] py-[100px] items-center justify-center">
			<div className="flex flex-col items-center px-[300px] gap-5">
				<h1 className="text-4xl text-center">Premium features without premium pricing</h1>
				<span className="text-xl text-center">Choose a plan that's right for you</span>
			</div>
		</div>
	);
}

export default PricingLanding;