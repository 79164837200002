import React from "react";
import { Link } from "react-router-dom";

import img_1 from "./img/Hero-section.png";

function HeroLanding() {
	return (
		<div name="hero" className="flex flex-row w-[1200px] h-[600px] px-[40px] py-[150px] rounded-b-lg bg-gradient-to-b from-white from-5% via-cyan-700 via-60% to-cyan-800 to-95%">
			<div name="text-and-buttons" className="flex flex-col items-start justify-center gap-y-10">
				<div name="text" className="flex flex-col items-start justify-center gap-y-5">
					<div name="title" className="flex text-white">
						<h1 className="text-5xl">Unlock Insights, Transform Data</h1>
					</div>
					<div name="sub-title" className="flex text-white">
						<p>Transform raw data into comprehensive and qualitative reports with the power of AI-driven analytics</p>
					</div>
				</div>
				<div className="flex flex-row">
					<Link to="/connection" className="btn rounded-full text-xl bg-white text-cyan-700 hover:bg-primary hover:text-white hover:border-white hover:scale-110 w-[200px] m-2">
						Get started today
					</Link>
					<Link to="/features" className="btn rounded-full text-xl bg-primary text-white hover:bg-white hover:text-cyan-700 border-white hover:scale-110  w-[200px] m-2">
						Learn more
					</Link>
				</div>
			</div>
			<div name="image" className="flex flex-col w-full relative">
				<div className="flex flex-col absolute top-[-50px] left-[0px]">
					<img src={img_1} alt="Dashboard" className="drop-shadow-xl" />
				</div>
			</div>
		</div>
	);
}

export default HeroLanding;
