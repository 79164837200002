import React from "react";

function ValidationLanding() {
	return (
		<div name="Validation by companies" className="flex w-[1200px] h-[290px] items-center justify-center">
			<h1 className="text-4xl">Trusted by world class companies</h1>
		</div>
	);
}

export default ValidationLanding;
