import React from "react";
import { Link } from "react-router-dom";

import img_p_6 from "./img/p-6.jpg";

function CTALanding() {
	return (
		<div name="CTA" className="flex w-[1200px] h-[300px] items-center justify-center px-10">
			<div className="flex flex-col items-center px-10 py-12 gap-10 bg-primary rounded-lg">
				<div className="flex flex-col gap-7">
					<span className="text-3xl text-white">Join 1,000+ customers changing their report creation</span>
					<div className="flex flex-row justify-center ">
						<img src={img_p_6} alt="brand 1" style={{ width: "auto", height: "30px" }} className="" />
					</div>
				</div>

				<Link to="/connection" className="btn rounded-full text-base bg-white text-cyan-700 hover:bg-primary hover:text-white hover:border-white hover:scale-110 w-[200px] ">
					Get started for free
				</Link>
			</div>
		</div>
	);
}

export default CTALanding;